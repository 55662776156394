<template>
    <div class="news_div">
        <div class="news_banner">
            <img :src="imgSrc[0]"/>
        </div>
        <div class="news_content_div allContentWidth">
            <div class="industry_trends_box">
                <p class="industry_trends_title">公司新闻</p>
                <p class="industry_trends_content" v-for="(item,index) in contentAll.company" :key="index">
                    <span class="content_span" @click="childClickFun('company',item)">{{ item.content }}</span>
                    <span>{{ item.time }}</span>
                </p>
            </div>
            <div class="industry_trends_box">
                <p class="industry_trends_title">行业动态</p>
                <p class="industry_trends_content" v-for="cItem in contentAll.industry" :key="cItem.id">
                    <span class="content_span" @click="childClickFun('industry',cItem)">{{ cItem.content }}</span>
                    <span>{{ cItem.time }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/libs/utils';

export default {
    components: {},
    computed: {},
    mounted() {
    },
    watch: {},
    methods: {
        childClickFun(arg, item) {
            utils.scrollTo();
            switch (arg) {
                case 'company':
                    this.$router.push(`/company_news/` + item.id);
                    break;
                case 'industry':
                    this.$router.push(`/industry_trends/` + item.id);
                    break;
            }
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        return {
            imgSrc: imgSrc,
            contentAll: {
                'industry': [
                    {id: 12, content: '海运界大地震！ONE和现代商船阳明海运打造新联盟，五年计划重塑全球海洋版图？', time: '2024-9'},
                    {id: 11, content: '新年快乐', time: '2024-2'},
                    {id: 10, content: '旭日东升，国旗飘扬，中华人民共和国已经成立74年。初心始终，砥砺前行，唱响了一首首中华赞歌。欢度国庆，爱我中华，愿祖国繁荣昌盛，欣欣向荣。', time: '2023-9'},
                    {id: 9, content: '重磅交易！赫伯罗特航运和贝恩资本科塔克家族达成协议，收购印度JMBPL港口物流40%股权', time: '2023-5'},
                    {id: 8, content: '好消息！中国铁路货运量去年完成39亿吨贡献4786亿元收入', time: '2023-1'},
                    {id: 7, content: '上海港去年完成4730万吞吐量，连续13年成为世界最繁忙港口', time: '2023-1'},
                    {id: 4, content: '2022年上半年中国造船国际市场份额保持全球领先', time: '2022-7-15'},
                    {id: 2, content: '德国汉堡港码头工人今起开始大罢工', time: '2022-7-14'},
                    {id: 5, content: '美国卡车司机罢工，铁路工人将罢工', time: '2022-7-14'},
                    {id: 3, content: '2022年全球Top20集装箱港口排名预测', time: '2022-7-13'},
                    {id: 1, content: '24000TEU集装箱巨轮在长兴岛交付', time: '2022-7-12'},
                    {id: 6, content: '国际航行船舶总吨位全国第二', time: '2022-7-11'},
                ],
                'company': [
                    {id: 11, content: '热烈庆祝上海东志国际货物运输代理有限公司中山分公司成立', time: '2024-12'},
                    {id: 10, content: 'WCA E-Commerce Conference', time: '2024-9'},
                    {id: 9, content: '2024年东志集团年度会议', time: '2024-2'},
                    // {id: 8, content: '热烈祝贺东志国际货物运输代理有限公司台湾分公司成立', time: '2023-9'},
                    {id: 7, content: '2023年东志团建之成都篇', time: '2023-5'},
                    {id: 6, content: '2023年东志集团销售会议', time: '2023-5'},
                    {id: 5, content: '东志集团获得CCLP（CargoWise Certified Logistics Provider）认证', time: '2023-1'},
                    {id: 4, content: 'Eastrong 马来西亚公司推出本地仓储运输服务', time: '2023-1'},
                    {id: 3, content: '东志集团上海分公司八月生日会现场', time: '2022-08-26'},
                    {id: 1, content: '东志集团上海分公司为员工举办生日会', time: '2022-06-24'},
                    {id: 2, content: '东志集团上海分公司荣获CEIV认证', time: '2022-03-03'},
                ],
            }
        }
    }
}
</script>
<style lang="less">
.news_div {
    .news_banner {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .news_content_div {
        display: flex;
        padding: 0 15px;

        .industry_trends_box {
            margin: 40px 0 60px 0;
            width: 50%;

            .industry_trends_title {
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 20px 0;
            }

            .industry_trends_content {
                line-height: 50px;
                display: flex;
                justify-content: space-between;

                .content_span {
                    width: 75%;
                }

                // &:hover{
                //     .content_span {
                //         cursor: pointer;
                //         text-decoration:underline;
                //     }
                // }
            }
        }

        .industry_trends_box { //:first-child
            padding-right: 100px;

            .industry_trends_content {
                &:hover {
                    .content_span {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .news_div {
        .news_content_div {
            flex-direction: column;

            .industry_trends_box {
                flex-direction: column;
                width: 100%;
            }

            .industry_trends_box:first-child {
                padding-right: 0px;
            }
        }
    }
}
</style>
