<template>
  <div class="home_div_box">
    <div>
      <div id="scrollBackTop"></div>
      <div class="banner_div">
        <swiper class="my_swiper" :options="swiperTopOption">
          <swiper-slide>
            <div class="mark"></div>
            <div class="banner_text">
              <p>互信 共赢</p>
            </div>
            <video autoplay loop muted width="100%" height="100%"
                   src="https://wetec-constrain.cdn.bcebos.com/material/dzhi/bg001.mp4"></video>
          </swiper-slide>
          <swiper-slide>
            <div class="mark"></div>
            <div class="banner_text">
              <p>互信 共赢</p>
            </div>
            <img :src="imgSrc[0]"/>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- 东志国际 -->
      <div class="dzgj">
        <div class="allContentWidth">
          <div class="dz_bg">
            <img :src="imgSrc[5]"/>
            <div class="dz_content">
              <div class="dz_title">
                <p>东志国际</p>
                <!-- <span>ABOUT US</span> -->
              </div>
              <div class="dz_text_title">
                <p>大洋洲贸易航线海运代理商前五名</p>
                <div class="dz_text_content">
                  上海东志国际货物运输代理有限公司成立于 2002
                  年，一直致力于在亚洲地区和国际上建立广泛的合作关系。总部设立于上海，拥有国际化战略政策及良好的经营业绩，已成功跻身于中国-大洋洲贸易航线海运代理商前五名。如今,作为众多世界一流行业协会的成员之一,我们奔赴世界各地参加专业会议,并始终秉承着
                  “客户第一” 的理念来建立合作关系。我们可靠的海运、空运和铁路运输能力使我们能够为客户提供定制化物流解决方案,
                  全面解决客户所面临的各类复杂性问题。
                </div>
              </div>
              <div class="dz_more" @click="aboutMoreFun({},'about_us')">
                了解更多
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dzgj_mobile">
        <img :src="imgSrc[5]" alt="">
        <div class="dz_content">
          <div class="dz_title">
            <p>东志国际</p>
            <!-- <span>ABOUT US</span> -->
          </div>
          <div class="dz_text_title">
            <p>大洋洲贸易航线海运代理商前五名</p>
            <div class="dz_text_content">
              上海东志国际货物运输代理有限公司成立于 2000
              年，一直致力于在亚洲地区和国际上建立广泛的合作关系。总部设立于上海，拥有国际化战略政策及良好的经营业绩，已成功跻身于中国-大洋洲贸易航线海运代理商前五名。如今,作为众多世界一流行业协会的成员之一,我们奔赴世界各地参加专业会议,并始终秉承着
              “客户第一” 的理念来建立合作关系。我们可靠的海运、空运和铁路运输能力使我们能够为客户提供定制化物流解决方案,
              全面解决客户所面临的各类复杂性问题。
            </div>
          </div>
          <div class="dz_more" @click="aboutMoreFun({},'about_us')">
            了解更多
          </div>
        </div>
      </div>
      <div class="view_content zhou_img_div">
        <div class="allContentWidth">
          <div class="icon_img_div">
            <img :src="imgSrc[7]"/>
          </div>
          <div class="map_content_second_div">
            <div class="map_content_second_box">
              <div class="map_content_num numBox">
                TOP
                <animate-number from="1" to="5" ref="myNum_c"></animate-number>
              </div>
              <div class="map_content_font">澳新航线 NVOCC 排名</div>
            </div>
            <div class="line_style"></div>
            <div class="map_content_second_box">
              <div class="map_content_num">
                <animate-number from="1" to="23" ref="myNum_g"></animate-number>
              </div>
              <div class="map_content_font">分公司</div>
            </div>
            <div class="line_style"></div>
            <div class="map_content_second_box">
              <div class="map_content_num">
                <animate-number from="1" to="50000" ref="myNum_l"></animate-number>
              </div>
              <div class="map_content_font"> 票（自拼箱）</div>
            </div>
            <div class="line_style"></div>
            <div class="map_content_second_box">
              <div class="map_content_num">
                <animate-number from="1" to="100000" ref="myNum_cl"></animate-number>
              </div>
              <div class="map_content_font">TEUs</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 主营业务 -->
      <div class="bussiness_div">
        <div class="mark"></div>
        <img src="../assets/img/bussiness.png" alt="" class="bg">
        <div class="bussiness_content allContentWidth">
          <div class="bussiness_text">
            <div class="left">
              <p>主营业务</p>
              <!-- <span>MAIN BUSINESS</span> -->
            </div>
            <div class="right">
              <div class="left_comma">
                <img :src="imgSrc[2]"/>
              </div>
              <div class="bu_content">
                <div class="bussiness_name">
                  <span>{{ bussinessActiveObj.name }}</span>
                  <span>{{ bussinessActiveObj.text }}</span>
                </div>
                <div class="bussiness_con" @click="aboutMoreFun(bussinessActiveObj,'main_business')">
                  {{ bussinessActiveObj.content }}
                </div>
              </div>
              <div class="right_comma">
                <div class="bussiness_more" @click="aboutMoreFun(bussinessActiveObj,'main_business')">
                  了解更多
                </div>
                <img :src="imgSrc[3]"/>
              </div>
            </div>
          </div>
          <div class="bussiness_tab">
            <div v-for="(i, index) in showCardList" :key="index" class="tab_item" @click="bussinessChangeFun(i)"
                 :class="{bussinessActive:active == i.id}">
              <img :src="i.src_c" alt="" v-if="active == i.id" class="item_img">
              <img :src="i.src" alt="" v-else class="item_img">
              <div class="tab_content">
                <span>{{ i.name }}</span>
                <img :src="i.icon_h_url" alt="" v-if="active == i.id">
                <img :src="i.icon_b_url" alt="" v-else>
              </div>
            </div>
          </div>
          <div class="business_page_box">
            <Page :total="imageSrcAll.length" :page-size="limit" :current="offset" size="small" @on-change="setOffset"
                  @on-page-size-change="setLimit" class="business_page"/>
          </div>
        </div>
      </div>
      <div class="bussiness_mobile">
        <div class="bus_title">
          <p>主营业务</p>
          <!-- <span>MAIN BUSINESS</span> -->
        </div>
        <swiper class="bussiness_swiper" :options="bussinessSwiperOption">
          <swiper-slide v-for="(item, indexB) in bussinessMobileAll" :key="indexB">
            <div>
              <img :src="item.src" alt="">
              <div class="slide_content">
                <span>{{ item.name }}</span>
                <p class="text">{{ item.content }}</p>
                <div class="more" @click="aboutMoreFun(item,'main_business')">了解更多</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-bus-pagination" slot="pagination"></div>
      </div>
      <!-- 行业案例 -->
      <div class="view_content industry_content">
        <swiper :options="caseSwiperOption">
          <swiper-slide class="swiper-no-swiping" v-for="(i, index2) in caseAll" :key="index2">
            <div class="industry_div  allContentWidth">
              <div style="display:flex;">
                <div class="left_img_div">
                  <div class="img_bj_div"></div>
                  <div class="img_overlay_div">
                    <img :src="i.src"/>
                  </div>
                </div>
                <div class="case_right_div">
                  <div class="industry_headline_c">行业案例</div>
                  <!-- <div class="industry_title_little">CASE</div> -->
                  <div class="industry_con_title">{{ i.title }}</div>
                  <div class="industry_con_text">{{ i.content }}</div>
                  <div class="industry_right_more" @click="aboutMoreFun(i,'case')">
                    了解更多
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-case-dot-pagination" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-prev-case" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-next-case" slot="button-next"></div>
      </div>
      <div class="industry_mobile">
        <div class="case_title">
          <p>行业案例</p>
          <!-- <span>CASE</span> -->
        </div>
        <swiper class="industry_swiper" :options="industrySwiperOption">
          <swiper-slide v-for="(item, indexC) in caseAll" :key="indexC">
            <div>
              <img :src="item.src" alt="">
              <div class="slide_content">
                <span>{{ item.title }}</span>
                <p class="text">{{ item.content }}</p>
                <div class="more" @click="aboutMoreFun(item,'case')">了解更多</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-case-pagination" slot="pagination"></div>
      </div>
      <!-- 地图 -->
      <div class="map_bg_div">
        <div class="map_bg">
          <img :src="imgSrc[8]"/>
        </div>
      </div>
      <!-- 新闻活动 -->
      <div class="view_content news-content">
        <div class="news_div_box allContentWidth">
          <div class="news-title-box">
            <div class="news-title-left">
              <p>新闻活动</p>
              <!-- <span>NEWS</span> -->
            </div>
            <div class="news-right-more" @click="aboutMoreFun({},'news')">
              了解更多
            </div>
          </div>
          <div class="new_content">
            <div class="new_content_div" v-for="(item,indexN) in newsContentAll" :key="indexN">
              <p><span class="ri_span">{{ item.day }}</span><span class="nian_span">/{{ item.year }}</span></p>
              <p class="new_p" @click="aboutMoreFun(item,'news_child')">{{ item.title }}</p>
              <div class="new_img" v-if="item.src!==''">
                <img :src="item.src"/>
                <!-- <div class="img_mask_birthday" v-if="indexN===0"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click="BackTopFun" v-show="backTopFlag" class="backTopDiv">
        <img src="../assets/img/back-top-icon.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import utils from '@/libs/utils';

export default {
  data() {
    var imgSrc = [];
    imgSrc.push(require('../assets/img/banner1.png'));//(0)
    imgSrc.push(require('../assets/img/big_zyyw.png')); //(1)
    imgSrc.push(require('../assets/img/“.png')); //(2)
    imgSrc.push(require('../assets/img/”.png')); //(3)
    imgSrc.push(require('../assets/img/about_more.png')); //(4)
    imgSrc.push(require('../assets/img/feiji.png')); //(5)
    imgSrc.push(require('../assets/img/about_more1.png')); //(6)
    imgSrc.push(require('../assets/img/icon.png')); //(7)
    imgSrc.push(require('../assets/img/map_z.png')); //(8)
    imgSrc.push(require('../assets/img/more2.png')); //(9)
    imgSrc.push(require('../assets/img/bussiness.png')); //(10)
    imgSrc.push(require('../assets/img/shi.png')); //(11)
    imgSrc.push(require('../assets/img/jt_y_b.png')); //(12)
    imgSrc.push(require('../assets/img/jt_y_c.png')); //(13)
    imgSrc.push(require('../assets/img/case_1.png')); //(14)
    imgSrc.push(require('../assets/img/case_left.png')); //(15)
    imgSrc.push(require('../assets/img/case_right.png')); //(16)
    imgSrc.push(require('../assets/img/1551655072440_.pic.png'));  //(17)
    return {
      imgSrc: imgSrc,
      active: 1,
      bussinessActiveObj: '',
      imageSrcAll: [ //主营业务
        {
          id: 1,
          src: require('../assets/img/buessiness_hy_h1.png'),
          src_c: require('../assets/img/buessiness_hy_c1.png'),
          name: '海运',
          text: 'Ocean Freight',
          content: '凭借20多年的NVOCC货代经验，我们与多个知名海运承运商共同建立了强大运输网络，每年可为客户运送100,000TEUs货物至全球各大角落。',
          icon_b_url: require('../assets/img/zyyw_hy_icon_b.png'),
          icon_h_url: require('../assets/img/zyyw_hy_icon_h.png'),
        },
        {
          id: 2,
          src: require('../assets/img/buessiness_ky_h.png'),
          src_c: require('../assets/img/buessiness_ky_c.png'),
          name: '空运',
          text: 'Air Freight',
          content: '我司与世界各大知名航空公司维持良好的长期合作关系，可为您定制各类空运解决方案。',
          icon_b_url: require('../assets/img/zyyw_ky_icon_b.png'),
          icon_h_url: require('../assets/img/zyyw_ky_icon_h.png'),
        },
        {
          id: 3,
          src: require('../assets/img/buessiness_htwl_h1.png'),
          src_c: require('../assets/img/buessiness_htwl_c1.png'),
          name: '合约物流',
          text: 'Contract Logistics',
          content: '我们可为您提供整合仓储，分销配送管理以及高效专业的供应链解决方案。',
          icon_b_url: require('../assets/img/zyyw_htwl_icon_b.png'),
          icon_h_url: require('../assets/img/zyyw_htwl_icon_h.png'),
        },
        {
          id: 4,
          src: require('../assets/img/buessiness_zotl_h1.png'),
          src_c: require('../assets/img/buessiness_zotl_c1.png'),
          name: '中欧铁路',
          text: 'Inter-Continents Rail',
          content: '跟随国家一带一路政策，我们可为您提供多条线路，定制化的国际铁路进出口运输解决方案。',
          icon_b_url: require('../assets/img/zyyw_zotl_icon_b.png'),
          icon_h_url: require('../assets/img/zyyw_zotl_icon_h.png'),
        },
        {
          id: 5,
          src: require('../assets/img/buessiness_yywl_h1.png'),
          src_c: require('../assets/img/buessiness_yywl_c1.png'),
          name: '医药物流',
          text: 'Pharmaceutical logistics',
          content: '自全球疫情泛滥，各国政府机构、慈善组织运输防疫物品需求急剧增加。',
          icon_b_url: require('../assets/img/zyyw_yywl_icon_b.png'),
          icon_h_url: require('../assets/img/zyyw_yywl_icon_h.png'),
        },
        {
          id: 6,
          src: require('../assets/img/buessiness_xmwl_h.png'),
          src_c: require('../assets/img/buessiness_xmwl_c.png'),
          name: '项目物流',
          text: 'Project Cargo',
          content: '我们拥有丰富的项目货运经验，专门从事散装、滚装、重型起重和综合项目。',
          icon_b_url: require('../assets/img/zyyw_xmwl_icon_b.png'),
          icon_h_url: require('../assets/img/zyyw_xmwl_icon_h.png'),
        },
      ],
      bussinessObj: {},
      bussinessMobileAll: [ //主营业务（移动端）
        {
          id: 1,
          src: require('../assets/img/bussiness_mobile_hy1.png'),
          name: '海运 Ocean Freight',
          content: '凭借20多年的NVOCC货代经验，我们与多个知名海运承运商共同建立了强大运输网络，每年可为客户运送100,000TEUs货物至全球各大角落。',
        },
        {
          id: 2,
          src: require('../assets/img/bussiness_mobile_ky.png'),
          name: '空运 Air Freight',
          content: '我司与世界各大知名航空公司维持良好的长期合作关系，可为您定制各类空运解决方案。',
        },
        {
          id: 3,
          src: require('../assets/img/bussiness_mobile_htwl1.png'),
          name: '合约物流 Contract Logistics',
          content: '我们可为您提供整合仓储，分销配送管理以及高效专业的供应链解决方案。',
        },
        {
          id: 4,
          src: require('../assets/img/bussiness_mobile_zotl1.png'),
          name: '中欧铁路 Inter-Continents Rail',
          content: '跟随国家一带一路政策，我们可为您提供多条线路，定制化的国际铁路进出口运输解决方案。',
        },
        {
          id: 5,
          src: require('../assets/img/bussiness_mobile_yywl1.png'),
          name: '医药物流 Pharmaceutical logistics',
          content: '自全球疫情泛滥，各国政府机构、慈善组织运输防疫物品需求急剧增加。',
        },
        {
          id: 6,
          src: require('../assets/img/bussiness_mobile_xmwl.png'),
          name: '项目物流 Project Cargo',
          content: '我们拥有丰富的项目货运经验，专门从事散装、滚装、重型起重和综合项目。',
        },
      ],
      caseList: [],
      swiperTopOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          type: 'bullets',//将分页的类型改为圆点（默认)
          clickable: true,// 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        loop: true, //无缝轮播
        grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      caseAll: [ //行业案例
        {
          id: 1,
          src: require('../assets/img/case_11.png'),
          title: '医药行业物流解决方案',
          content: '针对医药产品的特殊性及敏感性，药品运输过程中的各环节均需要进行严格的监督及管理。',
        },
        {
          id: 2,
          src: require('../assets/img/case_2.png'),
          title: '零售行业物流解决方案',
          content: '我们可提供极具竞争力的零售行业解决方案，全力协助你的产品以理想的状态、效率和成本竞争力进入市场。',
        },
        {
          id: 3,
          src: require('../assets/img/case_31.png'),
          title: '家具行业物流解决方案',
          content: '东志集团深耕货代行业二十余年，与国内外各大家具行业客户缔结了长期战略合作关系。',
        },
        {
          id: 4,
          src: require('../assets/img/case_4.png'),
          title: '能源、机械设备物流解决方案',
          content: '对于汽车、太阳能设备、大件设备、重型设备、精密仪器等大型特殊货物来说，运输时效和费用预算均有严格要求。东志集团深知大件设备跨境运输的困难性，以及运输各环节可能存在的各类风险。',
        },
      ],
      caseSwiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // 设置前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next-case",
          prevEl: ".swiper-button-prev-case",
        },
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: ".swiper-case-dot-pagination",
          type: 'bullets',//将分页的类型改为圆点（默认)
          clickable: true,// 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        loop: false, //无缝轮播
        grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
        speed: 0, //关闭动画
        allowTouchMove: false, // 这是用来禁止鼠标/手指拖动的，既然动效都不要了，那拖动可能也不要了
        observer: true,
        observeParents: true,
      },
      bussinessSwiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: ".swiper-bus-pagination",
          type: 'bullets',//将分页的类型改为圆点（默认)
          clickable: true,// 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        loop: true, //无缝轮播
        grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      industrySwiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: ".swiper-case-pagination",
          type: 'bullets',//将分页的类型改为圆点（默认)
          clickable: true,// 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        loop: true, //无缝轮播
        grabCursor: true, // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      showCardList: [],
      limit: 3,
      offset: 1,
      newsContentAll: [ //新闻活动(id与新闻活动列表统一，为了跳转正确)
        {
          id: 11,
          day: '12.16',
          year: '2024',
          title: '热烈庆祝上海东志国际货物运输代理有限公司中山分公司成立',
          src: require('../assets/img/zsgscl-1.jpg')
        },
        {
          id: 10,
          day: '09.29',
          year: '2024',
          title: 'WCA E-Commerce Conference',
          src: require('../assets/img/2024wca.jpg')
        },
        {
          id: 9,
          day: '02.07',
          year: '2024',
          title: '2024年东志集团年度会议',
          src: require('../assets/img/2024nh1.jpg')
        },
        // {
        //     id: 8,
        //     day: '09.27',
        //     year: '2023',
        //     title: '热烈祝贺东志国际货物运输代理有限公司台湾分公司成立',
        //     src: require('../assets/img/taiwan-gs1.jpg')
        // },
        {
          id: 7,
          day: '05.08',
          year: '2023',
          title: '2023年东志团建之成都篇',
          src: require('../assets/img/home_bnews7_1.jpeg')
        },

      ],
      backTopFlag: false,
    }
  },
  watch: {
    // '$route.meta': (val) => console.log(val)
  },
  created() {
    window.addEventListener('scroll', this.onScrolls)
    // this.bussinessChangeFun(this.imageSrcAll[0])
  },
  mounted() {
    this.page(); //主营业务
  },
  methods: {
    bussinessChangeFun(item) {
      this.active = item.id
      this.imageSrcAll.forEach((a) => {
        if (a.id === item.id) {
          this.bussinessActiveObj = item
        }
      })
    },
    aboutMoreFun(item, arg) {
      utils.scrollTo();
      switch (arg) {
        case 'about_us':
          this.$router.push(`/company_introduction`);
          break;
        case 'main_business':
          let query = 'tabActive=' + item.id;
          this.$router.push(`/main_business` + `?` + query);
          break;
        case 'case':
          let caseQuery = 'tabActive=' + item.id;
          this.$router.push(`/industry_case` + `?` + caseQuery);
          break;
        case 'news':
          this.$router.push(`/news`);
          break;
        case 'news_child':
          if (item.id !== 12) {
            this.$router.push(`/company_news/` + item.id);
          } else {
            this.$router.push(`/industry_trends/` + item.id);
          }
          break;
      }
    },
    dzAboutMore() {
    },
    setOffset(arg) {
      this.offset = arg;
      this.page();
    },
    setLimit(arg) {
      this.offset = 1;
      this.limit = arg;
      this.page();
    },
    page() {
      this.showCardList = this.imageSrcAll.slice((this.offset - 1) * this.limit, this.offset * this.limit);
      this.bussinessChangeFun(this.showCardList[0]);
    },
    BackTopFun() {
      document.getElementById("scrollBackTop").scrollIntoView({behavior: 'smooth'});
    },
    onScrolls() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop !== undefined) {
        this.backTopFlag = scrollTop > 200;
        this.startAnimate();
      }
    },
    startAnimate() {
      this.$refs.myNum_c.start();
      this.$refs.myNum_g.start();
      this.$refs.myNum_l.start();
      this.$refs.myNum_cl.start();
    },

  },
  destroyed() {
    window.removeEventListener('scroll', this.onScrolls);
  },
}
</script>
<style lang='less'>
.home_div_box {
  .banner_div { // 顶部banner
    position: relative;
    margin-bottom: 200px;

    .my_swiper {
      width: 100%;

      .swiper-slide {
        display: flex;
        height: auto;

        .mark {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0.6;
          background: #000;
        }

        .banner_text {
          position: absolute;
          left: 10%;
          top: 36%;

          p {
            font-size: 54px;
            font-weight: bold;
            color: #FFFFFF;
            margin-bottom: 33px;
          }

          span {
            font-size: 58px;
            font-weight: 600;
            color: #FFFFFF;
          }
        }
      }

      .swiper-pagination {
        // height: 100%;
        position: absolute;
        right: 50px;
        display: flex;
        flex-direction: column;
        top: 50%;
        width: auto !important;
        left: auto !important;
        bottom: auto !important;
        // width: auto;
        // left: 160px;
        // top: 50%;
        // bottom: 0;
        // transform: translateY(-50%);
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
        /* 设置小圆点样式 */

        .swiper-pagination-bullet {
          // width: 4px;
          // height: 66px;
          // border-radius: 0;
          // opacity: 1;
          // background: rgba(0,0,0,0.2);
          width: 8px;
          height: 8px;
          display: inline-block;
          border-radius: 100%;
          margin: 4px 4px;
          background: 0 0;
          opacity: 1;
          border: 1px solid #fff;
        }

        /* 设置小圆点激活样式 */

        .swiper-pagination-bullet-active {
          color: #fff;
          background: #fff;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }

  // 东志国际
  .dzgj {
    width: 100%;
    height: auto;
    display: block;
    // overflow: hidden;
    .dz_bg {
      height: 389px;
      display: flex;
      flex-wrap: wrap;
      background: #4B4D5E;
      position: relative;

      img {
        width: 55%;
        max-height: 395px;
        margin-right: 40px;
        margin-top: -28px;
      }

      .dz_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: -77px;
        padding-right: 50px;
        box-sizing: border-box;

        .dz_title {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          p {
            color: #404040;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 20px;
          }

          span {
            font-size: 12px;
            line-height: 12px;
            font-weight: bold;
            color: #A5A5A5;
          }
        }

        .dz_text_title {
          display: flex;
          flex-direction: column;

          p {
            font-size: 22px;
            font-weight: 600;
            color: #FFFFFF;
            margin-bottom: 30px;
          }

          .dz_text_content {
            font-size: 12px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 24px;
            margin-bottom: 23px;
            text-align: justify;
          }
        }

        .dz_more {
          width: 140px;
          height: 44px;
          color: #fff;
          line-height: 42px;
          text-align: center;
          border: 2px solid #A5A5A5;
          cursor: pointer;
        }
      }
    }
  }

  .dzgj_mobile {
    display: none;
  }

  .view_content {
    display: flex;
    justify-content: center;
  }

  .industry_content { //行业案例
    background: #fff;
    padding: 60px 0 90px 0;
    position: relative;
    display: block;
    height: 600px;

    .swiper-container {
      height: 100%;
    }

    .industry_div {
      height: 100%;
      display: flex;
      align-items: center;

      .left_img_div {
        position: relative;

        .img_bj_div {
          width: 636px;
          height: 353px;
          background: #000;
          opacity: 0.3;
          position: relative;
          // bottom: 37px;
        }

        .img_overlay_div {
          width: 636px;
          height: 353px;
          position: absolute;
          top: 38px;
          left: 33px;
          // background: green;
          img {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
        }
      }

      .case_right_div {
        margin-left: 80px;

        .industry_headline_c {
          font-size: 28px;
          font-weight: 600;
          color: #323232;
          line-height: 45px;
        }

        .industry_title_little {
          font-size: 12px;
          font-weight: bold;
          color: #A5A5A5;
          line-height: 39px;
        }

        .industry_con_title {
          color: #292929;
          font-size: 24px;
          line-height: 24px;
          margin: 15px 0 20px 0;
        }

        .industry_con_text {
          color: #000;
          font-size: 12px;
          line-height: 12px;
          margin: 0 0 70px 0;
          line-height: 24px;
        }

        .industry_right_more {
          width: 140px;
          height: 44px;
          color: #A5A5A5;
          line-height: 42px;
          text-align: center;
          border: 2px solid #A5A5A5;
          cursor: pointer;
        }
      }
    }

    .swiper-button-prev-case,
    .swiper-button-next-case {
      background-image: none;
      height: 100%;
      position: absolute;
      margin: 0;
      top: 0;
      z-index: 999;
      // color: red;
    }

    .swiper-button-prev-case {
      left: 30px;
      width: 57px;
      height: 57px;
      background-image: url('../assets/img/case_left.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translateY(-50%);

      &:after {
        content: "";
        width: 57px;
        height: 57px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        //   border-style: solid;
      }
    }

    .swiper-button-next-case {
      right: 30px;
      width: 57px;
      height: 57px;
      background-image: url('../assets/img/case_right.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translateY(-50%);

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: none;
        border-width: 10px;
        border-color: transparent transparent transparent #16f7ff;
        border-style: solid;
      }
    }

    .swiper-case-dot-pagination {
      display: flex;
      justify-content: center;
      margin: 20px 0 0 0;

      .swiper-pagination-bullet {
        margin: 0 10px;
        // background: #C5C5C5;
      }

      .swiper-pagination-bullet-active {
        color: #000;
        background: #000;
      }
    }
  }

  .industry_mobile {
    display: none;
  }

  .zhou_img_div {
    width: 100%;
    height: 394px;
    background: url(../assets/img/zhou.png) no-repeat center;

    .icon_img_div {
      display: flex;
      justify-content: center;
      margin: 90px 0 19px 0px;
    }

    .map_content_second_div {
      display: flex;
      justify-content: center;
      align-items: center;

      .map_content_second_box {
        width: 200px;
        text-align: center;
        margin: 0 20px;

        .map_content_num {
          font-size: 55px;
          font-weight: 600;
          color: #282828;
          // line-height: 704px;
        }

        .map_content_font {
          font-size: 14px;
          color: #9A9A9A;
          font-weight: 400;
        }
      }

      .line_style {
        width: 2px;
        height: 40px;
        background: #C7C3C2;
      }
    }
  }

  .map_bg_div { //地图
    .map_bg {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // 主营业务
  .bussiness_div {
    width: 100%;
    position: relative;
    display: block;

    .mark {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.4;
      background: #000;
    }

    .bg {
      width: 100%;
    }

    .bussiness_content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 30px 0 20px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .bussiness_text {
        width: 100%;
        flex: 1;
        display: flex;
        margin-bottom: 20px;

        .left, .right {
          flex: 1;
        }

        .left {
          display: flex;
          flex-direction: column;

          p {
            font-size: 28px;
            font-weight: 600;
            color: #FFFFFF;
            margin-bottom: 17px;
          }

          span {
            font-size: 12px;
            font-weight: bold;
            color: #FFFFFF;
          }
        }

        .right {
          display: flex;
          flex-direction: column;

          .left_comma {
            text-align: left;
          }

          .right_comma {
            display: flex;
            justify-content: space-between;
            margin: 20px 0 0 0;

            .bussiness_more {
              width: 140px;
              height: 44px;
              color: #fff;
              line-height: 42px;
              text-align: center;
              border: 2px solid #A5A5A5;
              margin: 10px 0 0 0;
              cursor: pointer;
            }

            // text-align: right;
          }

          .bu_content {
            // flex: 1;
            margin: 10px 0;
            cursor: pointer;
          }

          .bussiness_name {
            color: #fff;
            font-size: 24px;
            // line-height:24px;
            // margin: 15px 0 20px 0;
          }

          .bussiness_con {
            color: #fff;
            font-size: 14px;
            line-height: 14px;
            line-height: 40px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .bussiness_tab {
        width: 100%;
        display: flex;
        justify-content: center;

        .tab_item {
          // flex: 1;
          display: flex;
          margin-right: 8px;
          background: rgba(0, 0, 0, 0.6);
          padding: 18px 50px 18px 23px;
          box-sizing: border-box;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          .item_img {
            margin-right: 12px;
          }

          .tab_content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            span {
              width: 80px;
              font-size: 16px;
              font-weight: bold;
              color: #FFFFFF;
            }

            img {
              width: 22px;
              height: 22px;
            }
          }
        }

        .bussinessActive {
          background: rgba(255, 255, 255, 0.5);

          .tab_content {
            span {
              color: #000;
            }
          }
        }
      }

      .business_page_box {
        display: flex;
        justify-content: center;
        margin: 20px 0 0 0;

        .business_page {
          display: inline-block;

          .ivu-page-prev, .ivu-page-next {
            display: none;
          }

          .ivu-page-item {
            min-width: 89px !important;
            height: 5px !important;
            background: rgba(0, 0, 0, .4);

            a {
              display: none;
            }
          }

          .ivu-page-item-active {
            background: #fff !important;
          }
        }
      }
    }
  }

  .bussiness_mobile {
    display: none;
  }

  .news_div_box { //新闻活动
    margin: 60px 0;
    width: 100%;

    .news-title-box {
      display: flex;
      justify-content: space-between;
      // padding:0 15px;
      .news-title-left {
        display: flex;
        flex-direction: column;

        p {
          font-size: 28px;
          font-weight: 600;
          color: #262626;
          line-height: 45px;
        }

        span {
          font-size: 12px;
          font-weight: bold;
          color: #A5A5A5;
          line-height: 39px;
        }
      }

      .news-right-more {
        width: 140px;
        height: 44px;
        color: #A5A5A5;
        line-height: 42px;
        text-align: center;
        border: 2px solid #A5A5A5;
        cursor: pointer;
      }
    }

    .new_content {
      display: flex;
      margin-top: 20px;
      // justify-content: space-around;
      .new_content_div {
        width: 100%;
        margin-right: 50px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        .ri_span {
          font-size: 36px;
          color: #ADADAD;
        }

        .nian_span {
          font-size: 16px;
          color: #ADADAD;
        }

        .new_p {
          font-size: 18px;
          color: #4D4D4D;
        }

        &:hover {
          .new_p {
            text-decoration: underline;
          }

          // .new_p:last-child{
          //     text-decoration:none;
          // }
        }

        .new_img {
          width: 307px;
          height: 216px;
          margin-top: 15px;
          position: relative;

          .img_mask_birthday {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            opacity: 0.2;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .new_content_div_box {
          height: 216px;
          position: relative;

          .new_div_box {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #A6A6A6;
            line-height: 23px;
          }

          .img_new_about {
            bottom: 0;
            position: absolute;
          }
        }
      }
    }
  }

  .backTopDiv {
    position: fixed;
    width: 80px;
    right: 4%;
    bottom: 5%;
    display: block;
    z-index: 999;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

}

// and (min-width:350px)
@media screen and (min-width: 300px) and (max-width: 1200px) {
  .home_div_box {
    .banner_div {
      margin-bottom: 0;

      .my_swiper {
        .swiper-pagination {
          left: 10px;

          .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-bottom: 5px;
          }
        }

        .swiper-slide {
          .banner_text {
            top: 42%;

            p {
              font-size: 34px;
              line-height: 24px;
              margin-bottom: 8px;
            }

            span {
              font-size: 20px;
            }
          }
        }
      }
    }

    // 东志国际
    .dzgj {
      display: none;
    }

    .dzgj_mobile {
      display: block;

      img {
        width: 100%;
        font-size: 0;
        margin: 0;
        display: block;
      }

      .dz_content {
        width: 100%;
        padding: 30px 40px;
        box-sizing: border-box;
        background: #4B4D5E;
        color: #fff;
        margin: 0;

        .dz_title {
          display: flex;
          flex-direction: column;
          margin-bottom: 40px;

          p {
            color: #fff;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 20px;
          }

          span {
            font-size: 12px;
            line-height: 12px;
            font-weight: bold;
            color: #A5A5A5;
          }
        }

        .dz_text_title {
          display: flex;
          flex-direction: column;

          p {
            font-size: 20px;
            font-weight: 600;
            color: #FFFFFF;
            margin-bottom: 30px;
          }

          .dz_text_content {
            font-size: 12px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 24px;
            margin-bottom: 23px;
            text-align: justify;
          }
        }

        .dz_more {
          width: 140px;
          height: 44px;
          color: #fff;
          line-height: 42px;
          text-align: center;
          border: 2px solid #A5A5A5;
        }
      }
    }

    // 洲文字
    .zhou_img_div {
      .map_content_second_div {
        .map_content_second_box {
          margin: 0 8px;

          .map_content_num {
            font-size: 28px;
          }
        }
      }
    }

    // 主营业务
    .bussiness_div {
      display: none;
    }

    .bussiness_mobile {
      display: block;
      margin-bottom: 30px;

      .bus_title {
        display: flex;
        align-items: center;
        padding: 0 15px;

        p {
          margin-right: 10px;
          font-size: 28px;
        }
      }

      .bussiness_swiper {
        .swiper-slide {
          padding: 15px;
          display: flex;
          flex-direction: column;

          > div {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
          }

          img {
            width: 100%;
          }

          .slide_content {
            padding: 15px;
            display: flex;
            flex-direction: column;

            span {
              color: #000;
              font-size: 24px;
            }

            p {
              color: #000;
              font-size: 12px;
            }

            .more {
              width: 140px;
              height: 44px;
              color: #000;
              line-height: 42px;
              text-align: left;
            }
          }
        }
      }

      .swiper-bus-pagination {
        padding: 0 15px;

        .swiper-pagination-bullet {
          margin-right: 5px;
        }
      }
    }

    .industry_content {
      display: none;
    }

    //行业案例
    .industry_mobile {
      display: block;
      margin-bottom: 30px;

      .case_title {
        display: flex;
        align-items: center;
        padding: 0 15px;

        p {
          margin-right: 10px;
          font-size: 28px;
        }
      }

      .industry_swiper {
        .swiper-slide {
          padding: 15px;
          display: flex;
          flex-direction: column;

          > div {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
          }

          img {
            width: 100%;
          }

          .slide_content {
            padding: 15px;
            display: flex;
            flex-direction: column;

            span {
              color: #000;
              font-size: 24px;
            }

            p {
              color: #000;
              font-size: 12px;
            }

            .more {
              width: 140px;
              height: 44px;
              color: #000;
              line-height: 42px;
              text-align: left;
            }
          }
        }
      }

      .swiper-case-pagination {
        padding: 0 15px;

        .swiper-pagination-bullet {
          margin-right: 5px;
        }
      }
    }

    // 新闻活动
    .news-content {
      padding: 0 15px;

      .new_content {
        display: flex;
        flex-direction: column;

        .new_content_div {
          display: flex;
          flex-direction: column;
          margin-left: 0;
          padding: 0 20px;
          margin-bottom: 20px;

          .new_content_div_box {
            height: auto;
          }

          .new_img {
            display: none;
          }
        }
      }
    }

    .backTopDiv {
      width: 50px;
    }
  }
}
</style>

